import {
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from 'src/app/modules/app-material.module';

@Component({
  selector: 'app-card',
  standalone: true,
  imports: [CommonModule, AppMaterialModule],
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardComponent {
  @HostBinding('class') classes: string[] = [
    'custom-card',
    'mat-elevation-z4',
    'py-3',
    'rounded',
    'd-block',
  ];
  @Input() loading = false;
}
